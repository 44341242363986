.rouble {
  position: relative;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
}
.rouble:before {
  position: absolute;
  top: 63%;
  left: 0;
  right: 1px;
  border-top: 1px solid #000;
  content: '';
}
body {
  font-size: 10px;
  max-width: 1920px;
  margin: 0 auto;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
  outline: none;
}
/* Fonts --------------------->*/
@font-face {
  font-family: 'Exo2-Regular';
  src: url('fonts/Exo-regular/Exo2-Regular.eot');
  src: url('fonts/Exo-regular/Exo2-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/Exo-regular/Exo2-Regular.woff') format('woff'), url('fonts/Exo-regular/Exo2-Regular.ttf') format('truetype'), url('fonts/Exo-regular/Exo2-Regular.otf') format('opentype'), url('fonts/Exo-regular/Exo2-Regular.svg#Exo2-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'Exo2-Light';
  src: url('fonts/Exo-light/Exo2-Light.eot');
  src: url('fonts/Exo-light/Exo2-Light.eot?#iefix') format('embedded-opentype'), url('fonts/Exo-light/Exo2-Light.woff') format('woff'), url('fonts/Exo-light/Exo2-Light.ttf') format('truetype'), url('fonts/Exo-light/Exo2-Light.otf') format('opentype'), url('fonts/Exo-light/Exo2-Light.svg#Exo2-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'Exo2-Bold';
  src: url('fonts/Exo-bold/Exo2-Bold.eot');
  src: url('fonts/Exo-bold/Exo2-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/Exo-bold/Exo2-Bold.woff') format('woff'), url('fonts/Exo-bold/Exo2-Bold.ttf') format('truetype'), url('fonts/Exo-bold/Exo2-Bold.otf') format('opentype'), url('fonts/Exo-bold/Exo2-Bold.svg#Exo2-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
/* <----------------- End Fonts*/
/* Header Slider -------------->*/
.slide-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.slide-container.active {
  /*visibility: visible;*/
}
.slide-container.slide-1 {
  animation: sizeIn 5s alternate;
  animation-fill-mode: forwards;
}
.slide-container.slide-2 {
  animation: sizeOut 5s alternate;
  animation-fill-mode: forwards;
}
.slide-container.slide-3 {
  animation: sizeIn 5s alternate;
  animation-fill-mode: forwards;
}
.slide-holder {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto !important;
  overflow: hidden;
  margin: 0;
}
@keyframes sizeIn {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
  }
}
@keyframes sizeOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}
@keyframes easyShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  33.333% {
    opacity: 0;
  }
  66.666% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade2 {
  0% {
    opacity: 0;
  }
  33.333% {
    opacity: 1;
  }
  66.666% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade3 {
  0% {
    opacity: 0;
  }
  33.333% {
    opacity: 0;
  }
  66.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*<-----------------End Header Slider*/
.header {
  position: relative;
  z-index: 1000;
  text-align: center;
  padding-bottom: 150px;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .header {
    text-align: left;
    padding-bottom: 350px;
    height: 100vh;
  }
}
.header:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  z-index: 5;
}
.header .container {
  position: relative;
  z-index: 10;
}
.header .go-down {
  transform: rotate(-90deg);
  padding-left: 30px;
  background: url(../img/arow-down.png) no-repeat center left;
  position: absolute;
  bottom: 70px;
  left: 50%;
  color: #fff;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 10;
  margin-left: 125px;
}
.top-part {
  padding: 15px 0 27px;
  text-align: center;
  opacity: 0;
  transition: opacity 1s 1s;
}
.js .animated .top-part {
  animation: slideInDown 1s 1s;
  opacity: 1;
}
@media (min-width: 768px) {
  .top-part {
    text-align: left;
  }
}
.logo {
  display: inline-block;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Exo2-Bold';
  line-height: 1.9;
}
@media (min-width: 350px) {
  .logo {
    font-size: 30px;
    line-height: 1.5432;
    margin-left: -5px;
  }
}
@media (min-width: 768px) {
  .logo {
    float: left;
  }
}
.logo span {
  color: #b0cb1f;
}
.logo p {
  color: rgba(255, 255, 255, 0.5);
  text-transform: none;
  font-size: 14px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  float: right;
  padding: 7px 0 7px 20px;
  margin: 0 0 0 20px;
  font-family: 'Exo2-Regular';
  line-height: 1.2;
  text-align: left;
}
.info-right-part {
  float: left;
  clear: both;
  width: 100%;
  padding: 0 10px;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .info-right-part {
    float: right;
  }
}
@media (min-width: 440px) {
  .info-right-part {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .info-right-part {
    float: right;
    clear: none;
    width: auto;
    padding: 0;
  }
}
.call {
  display: block;
  margin: 10px 0;
}
@media (min-width: 440px) {
  .call {
    float: left;
    margin: 0 10px 0 0;
  }
}
.call a {
  display: block;
  color: #fff;
  font-size: 20px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
}
.call a:hover {
  color: #c7e235 !important;
}
.call .callback {
  display: inline-block;
  font-size: 14px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px dashed #7b7a77;
}
@media (min-width: 440px) {
  .call .callback {
    float: right;
  }
}
.call .callback:hover {
  color: #c7e235;
}
.general-site {
  display: inline-block;
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .general-site {
    display: none;
  }
}
@media (min-width: 440px) {
  .general-site {
    float: right;
  }
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none!important;
  color: #fff !important;
}
a:active {
  text-decoration: none!important;
  color: #fff !important;
}
a:focus {
  color: #fff !important;
  text-decoration: none!important;
  outline: none !important;
}
.general-site a {
  color: #fff;
  font-family: 'Exo2-Regular';
  font-size: 14px;
  text-transform: uppercase;
  line-height: 30px;
  white-space: nowrap;
  border: 1px solid #b0cb1f;
  display: inline-block;
  padding: 0 10px;
  margin-top: 7px;
}
.general-site a:hover {
  background: #b0cb1f;
  color: #fff;
}
.general-site a:active {
  background: #90a714!important;
}
.navbar-custom {
  margin-bottom: 50px;
  opacity: 0;
  transition: opacity 1s 2s;
}
.js .animated .navbar-custom {
  animation: slideInDown 1s 2s;
  opacity: 1;
}
.navbar-custom ul {
  text-align: center;
}
@media (min-width: 440px) {
  .navbar-custom ul {
    text-align: left;
    margin-left: 20px;
  }
}
.navbar-custom li {
  margin-bottom: 22px;
}
.navbar-custom li:last-child {
  margin-bottom: 0;
}
.navbar-custom a {
  font-size: 13px;
  color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.navbar-custom a:hover {
  color: #c7e235!important;
}
.deskcription {
  padding: 40px 70px 40px 0;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  border: 6px solid #b0cb1f;
  border-left: none;
  text-align: left;
  position: relative;
  margin-bottom: 15px;
  opacity: 0;
  transition: opacity 1s 3s;
}
.js .animated .deskcription {
  animation: slideInLeft 1s 3s;
  opacity: 1;
}
@media (min-width: 768px) {
  .deskcription {
    background: url(../img/border-first-screen.png) no-repeat center right;
    border: none;
  }
}
@media (min-width: 992px) {
  .deskcription {
    margin-left: 90px;
  }
}
@media (min-width: 1200px) {
  .deskcription {
    float: left;
    margin-bottom: 0;
  }
}
.deskcription:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 6px;
  background-color: #b0cb1f;
}
@media (min-width: 768px) {
  .deskcription:after {
    display: none;
  }
}
.deskcription:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 24px;
  width: 6px;
  background-color: #b0cb1f;
}
@media (min-width: 768px) {
  .deskcription:before {
    display: none;
  }
}
.deskcription h2 {
  margin: 0;
  font-size: 22px;
  font-family: 'Exo2-Bold';
  padding-left: 10px;
  margin-bottom: 5px;
}
@media (min-width: 480px) {
  .deskcription h2 {
    font-size: 36px;
  }
}
@media (min-width: 768px) {
  .deskcription h2 {
    padding: 0;
  }
}
.deskcription p {
  margin: 0;
  font-family: 'Exo2-Regular';
  font-size: 14px;
  padding-left: 10px;
}
@media (min-width: 480px) {
  .deskcription p {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .deskcription p {
    padding: 0;
  }
}
.deskcription br {
  display: none;
}
@media (min-width: 480px) {
  .deskcription br {
    display: block;
  }
}
.application-holder {
  font-family: 'Exo2-Light';
  display: inline-block;
  text-align: left;
  padding: 0 10px;
  opacity: 0;
  transition: opacity 1s 3.5s;
}
.js .animated .application-holder {
  animation: slideInRight 1s 3.5s;
  opacity: 1;
}
@media (min-width: 360px) {
  .application-holder {
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .application-holder {
    float: right;
    margin-top: 45px;
  }
}
.application-holder h3 {
  margin: 0;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (min-width: 480px) {
  .application-holder h3 {
    font-size: 30px;
  }
}
.application-holder p {
  font-size: 18px;
  color: #fff;
  margin: 15px 0 20px;
  text-align: left;
}
.application-holder a {
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'Exo2-Bold';
  color: #fff;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #b0cb1f;
}
.application-holder a:hover {
  background-color: #c7e235;
}
.application-holder a:active {
  background-color: #90a714;
}
.presentation-product {
  padding: 45px 0 50px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .presentation-product {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .presentation-product {
    background: url(../img/elipsis.png) no-repeat top 45px right;
  }
}
.col-custom-6 {
  box-sizing: border-box;
  float: left;
  width: 50%;
}
.col-custom-3 {
  float: left;
  width: 25%;
}
.col-title {
  width: 100%;
  float: none;
  box-sizing: border-box;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .col-title {
    float: left;
    width: 50%;
    margin-bottom: 0;
  }
}
.col-holder {
  position: relative;
  display: inline-block;
  float: none;
  width: 100%;
  max-width: 586px;
  margin-top: -10px;
}
@media (min-width: 768px) {
  .col-holder {
    float: left;
    width: 50%;
    margin-top: 0;
  }
}
.col-holder:first-child {
  margin-top: 0;
}
.name-holder h2 {
  text-transform: uppercase;
  font-size: 36px;
  font-family: 'Exo2-Bold';
  padding: 0 0 7px 27px;
  border-left: 4px solid #b0cb1f;
  margin: 0 0 10px 0;
  text-align: left;
}
@media (min-width: 380px) {
  .name-holder h2 {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .name-holder h2 {
    margin-left: 70px;
    font-size: 30px;
  }
}
@media (min-width: 1200px) {
  .name-holder h2 {
    font-size: 45px;
  }
}
.name-holder ul {
  padding-left: 27px;
}
@media (min-width: 992px) {
  .name-holder ul {
    margin-left: 70px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .name-holder ul {
    margin-left: 90px;
  }
}
.name-holder ul li {
  font-size: 16px;
  font-family: 'Exo2-Regular';
  list-style: disc;
  text-align: left;
}
@media (min-width: 992px) {
  .name-holder ul li {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .name-holder ul li {
    font-size: 16px;
  }
}
.content-box {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #eeeeee;
  padding: 0 12px;
}
@media (min-width: 992px) {
  .content-box {
    padding-left: 32px;
  }
}
.content-box:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -22px;
  border: 23px solid transparent;
  border-top: 23px solid #eee;
  transform: rotate(-45deg);
}
.content-box.box-left:after {
  right: -22px;
  left: auto;
  transform: rotate(45deg);
}
.content-box.green {
  background: #b0cb1f;
}
.content-box.green:after {
  border-top-color: #b0cb1f;
}
.content-box.green p {
  color: #fff;
}
.content-box .holder {
  position: relative;
  height: 100%;
}
.content-box p {
  color: #000;
  font-size: 17px;
  font-family: 'Exo2-Light';
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin: 0;
  text-align: left;
}
@media (min-width: 992px) {
  .content-box p {
    font-size: 22px;
  }
}
.box-left {
  left: 0;
}
.box-right {
  right: 0;
}
.photo-gallery {
  padding: 40px 0;
  background: url(../img/gallery-bg.png) repeat;
}
.photo-gallery h2 {
  font-family: 'Exo2-Bold';
  text-transform: uppercase;
  font-size: 25px;
  border-left: 4px solid #b0cb1f;
  margin: 0;
  padding-left: 30px;
}
@media (min-width: 768px) {
  .photo-gallery h2 {
    font-size: 45px;
  }
}
/*First Gallery -------->*/
.reelscout-slider-body {
  position: relative;
  z-index: 150;
}
.reelscout-slider-outer.maximized {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 5px;
  z-index: 20000 !important;
}
@media (min-width: 768px) {
  .reelscout-slider-outer.maximized {
    padding: 20px;
  }
}
.reelscout-slider-outer.maximized .panel {
  background-color: #fff;
  opacity: 1;
  max-width: 1860px;
  margin: 0 auto;
}
.reelscout-slider-outer.maximized .slider-preview {
  display: none;
}
.reelscout-slider-outer .toggle-maximize {
  cursor: pointer;
}
.slider-preview {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.slider-preview li {
  cursor: pointer;
  width: 75px;
  height: 75px;
  border: solid 3px transparent;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 5px;
}
.reelscout-slider ul li.active img {
  cursor: default;
}
/*.reelscout-slider-outer .slider-preview li  {
//    flex-direction: row;
justify-content: space-between;
cursor:  pointer;
width:  75px;
height:  75px;
border:  solid 3px transparent;
display:  inline-block;
//    background-attachment:  initial;
background-position:  center;
background-repeat:  no-repeat;
background-size:  cover;
margin-right:  5px;
}*/
.reelscout-slider-outer .slider-preview li.active {
  border: solid 3px #b0cb1f;
}
.reelscout-slider {
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  text-align: left;
}
.reelscout-slider ul.slider {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}
.reelscout-slider ul.slider li {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: .7;
}
.reelscout-slider ul.slider li.active {
  opacity: 1;
}
.reelscout-slider ul.slider li img {
  opacity: 0;
}
.reelscout-slider ul.slider li:hover {
  cursor: pointer;
}
.maximized .gallery-description h5 {
  margin-bottom: 5px;
}
.maximized .gallery-description br {
  display: none;
}
.maximized .gallery-description p {
  margin-bottom: 0;
}
.maximized .gallery-description.active {
  display: block;
}
.arrow_left1 {
  position: absolute;
  background: url(../img/arrow_sprite.png) no-repeat;
  width: 38px;
  height: 38px;
  top: 232px;
  left: -20px;
}
.arrow_left1:hover {
  background-position: 0 -38px;
}
.arrow_left1:active {
  background-position: 0 -76px;
}
.arrow_right1 {
  position: absolute;
  background: url(../img/arrow_sprite.png) no-repeat;
  width: 38px;
  height: 38px;
  transform: rotate(180deg);
  left: 18px;
  top: 232px;
}
.arrow_right1:hover {
  background-position: 0 -38px;
}
.arrow_right1:active {
  background-position: 0 -76px;
}
.gallery-description {
  padding: 15px 15px 30px 0;
  background-color: #fff;
  max-width: 400px;
  height: 200px;
  display: none;
  padding-bottom: 1px;
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .gallery-description {
    position: relative;
    margin-top: -60px;
    padding: 15px 15px 0 10px;
    background-color: #fff;
    width: 90%;
    min-height: 100px;
    display: none;
  }
  .arrow_left1 {
    top: 263px;
    left: 15px;
  }
  .arrow_right1 {
    left: 53px;
    top: 263px;
  }
}
@media only screen and (max-width: 750px) and (min-width: 320px) {
  .gallery-description {
    position: relative;
    margin-top: -20px;
    padding: 15px 15px 0 10px;
    background-color: #fff;
    width: 90%;
    min-height: 100px;
    display: none;
  }
  .arrow_left1 {
    top: 100px;
    left: -15px;
  }
  .arrow_right1 {
    left: 94%;
    top: 100px;
  }
}
.gallery-description.active {
  display: block;
}
@media (min-width: 1200px) {
  .gallery-description {
    position: absolute;
    top: 70px;
    left: -20px;
    padding: 15px 15px 30px 0;
    max-width: 400px;
  }
}
.gallery-description br {
  display: none;
}
@media (min-width: 1200px) {
  .gallery-description br {
    display: block;
  }
}
.gallery-description h5 {
  text-transform: uppercase;
  font-size: 17px;
  font-family: 'Exo2-Bold';
  margin: 0 0 0 25px;
  text-align: left;
}
@media (min-width: 340px) {
  .gallery-description h5 {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .gallery-description h5 {
    margin: 0 0 0 25px;
  }
}
.gallery-description p {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Exo2-Light';
  margin: 0 0 20px 0;
  text-align: left;
}
@media (min-width: 1200px) {
  .gallery-description p {
    margin: 0 0 20px 25px;
  }
}
.reviews {
  padding: 45px 0 50px 0;
  text-align: center;
}
@media (min-width: 1200px) {
  .reviews {
    background: url(../img/elipsis.png) no-repeat top 45px right;
  }
}
.reviews h2 {
  font-family: 'Exo2-Bold';
  text-transform: uppercase;
  font-size: 25px;
  border-left: 4px solid #b0cb1f;
  margin: 0;
  padding-left: 30px;
  margin-bottom: 40px;
  text-align: left;
}
@media (min-width: 768px) {
  .reviews h2 {
    font-size: 45px;
  }
}
.gallery_big {
  position: relative;
}
@media (min-width: 1200px) {
  .gallery_big {
    margin-left: 63px;
  }
  .portfolio__gallery-list img:nth-child(3) {
    margin-right: 0 !important;
  }
  .portfolio__gallery-list img:nth-child(6) {
    margin-right: 0 !important;
  }
}
.portfolio__gallery_big-wrap {
  display: inline-block;
}
@media (min-width: 992px) {
  .portfolio__gallery_big-wrap {
    float: left;
  }
  .portfolio__gallery-list img:nth-child(3) {
    margin-right: 0 !important;
  }
  .portfolio__gallery-list img:nth-child(6) {
    margin-right: 0 !important;
  }
}
.portfolio__gallery-list {
  font-size: 0;
  width: auto;
}
.portfolio__gallery-list img:nth-child(3) {
  margin-right: 0 !important;
}
.portfolio__gallery-list img:nth-child(6) {
  margin-right: 0 !important;
}
@media (min-width: 768px) {
  .portfolio__gallery-list {
    text-align: center;
  }
  .portfolio__gallery-list img:nth-child(3) {
    margin-right: 0 !important;
  }
  .portfolio__gallery-list img:nth-child(6) {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 766px) and (min-width: 320px) {
  .portfolio__gallery-list {
    display: none;
  }
}
@media (min-width: 992px) {
  .portfolio__gallery-list {
    width: 420px;
    height: 525px;
    overflow-y: scroll;
    overflow-x: hidden;
    float: right;
    margin-top: 35px;
  }
}
@media (min-width: 1200px) {
  .portfolio__gallery-list {
    width: 590px;
    overflow: hidden;
  }
}
@media (min-width: 1200px) {
  .portfolio__gallery-list .nomargin-right {
    margin-right: 0;
  }
}
.portfolio__gallery-list img {
  cursor: pointer;
  margin: 0 10px 20px 10px;
  width: auto;
  height: auto;
  border: 3px solid transparent;
}
@media (min-width: 992px) {
  .portfolio__gallery-list img {
    margin: 0 20px 20px 0;
  }
}
@media (min-width: 1200px) {
  .portfolio__gallery-list img {
    margin: 0 30px 20px 0;
  }
}
.cycle-slide img {
  width: 100%;
  transition: 0s;
}
@media (min-width: 492px) {
  .cycle-slide img {
    width: auto;
  }
}
img.cycle-pager-active {
  border-color: #b0cb1f;
}
.zakaz {
  padding: 30px 0 50px 0;
  background: url(../img/zakaz-bg.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
}
@media (min-width: 768px) {
  .zakaz {
    padding: 110px 0;
  }
}
.zakaz.second-section {
  background: url(../img/second-zakaz-bg.jpg) no-repeat center center;
  background-size: cover;
}
@media (min-width: 1200px) {
  .zakaz.second-section {
    background-position: center center;
  }
}
.zakaz:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  content: '';
}
.zakaz .container {
  position: relative;
  z-index: 1;
}
.zakaz h3 {
  font-size: 30px;
  color: #fff;
  font-family: 'Exo2-Bold';
}
@media (min-width: 392px) {
  .zakaz h3 {
    font-size: 36px;
  }
}
.zakaz p {
  font-size: 18px;
  color: #fff;
  font-family: 'Exo2-Regular';
}
.zakaz .form-holder {
  text-align: center;
}
@media (min-width: 992px) {
  .zakaz .form-holder {
    float: right;
    text-align: left;
  }
}
.zakaz .form-holder form {
  display: inline-block;
  width: 100%;
  max-width: 362px;
}
.zakaz input {
  border-radius: 0;
  border: none;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 19px;
  height: 42px;
  font-size: 16px;
  font-family: 'Exo2-Regular';
  color: #6e6e6e;
}
.zakaz input:focus {
  border: 2px solid #b0cb1f;
}
.zakaz input.error {
  border: 2px solid red;
}
.zakaz textarea {
  height: 98px;
  resize: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0 0 16px;
  font-size: 16px;
  font-family: 'Exo2-Regular';
  color: #6e6e6e;
  margin-bottom: 10px;
}
.zakaz .file-holder {
  position: relative;
  height: 42px;
  width: 100%;
}
.zakaz .file-holder input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.zakaz button {
  display: block;
  width: 100%;
  height: 48px;
  text-align: center;
  color: #fff;
  background-color: #b0cb1f;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Exo2-Bold';
  border: 0;
  margin-top: 20px;
  outline: none;
}
.zakaz button:hover {
  background-color: #c7e235;
}
.zakaz button:active {
  background-color: #90a714;
}
.zakaz-placeholder {
  display: inline-block;
  opacity: 0;
  transition: opacity 1s 1s;
}
.js .animated .zakaz-placeholder {
  animation: slideInLeft 1s 1.5s;
  opacity: 1;
}
@media (min-width: 768px) {
  .zakaz-placeholder {
    background: url(../img/border-zakaz-screen.png) no-repeat top right;
    padding: 7px 150px 42px 0;
  }
}
@media (min-width: 992px) {
  .zakaz-placeholder {
    margin-top: 79px;
  }
}
@media (min-width: 1200px) {
  .zakaz-placeholder {
    margin-left: 55px;
  }
}
.why-we {
  padding: 45px 0;
}
@media (min-width: 992px) {
  .why-we {
    padding: 60px 0 50px 0;
  }
}
.why-we h2 {
  font-family: 'Exo2-Bold';
  text-transform: uppercase;
  font-size: 25px;
  border-left: 4px solid #b0cb1f;
  margin: 0;
  padding-left: 30px;
  text-align: left;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .why-we h2 {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .why-we h2 {
    margin-bottom: 90px;
  }
}
.list-desk {
  height: 565px;
}
@media (min-width: 992px) {
  .list-desk {
    margin-bottom: 110px;
  }
}
.list-desk img {
  width: 90%;
}
@media (min-width: 530px) {
  .list-desk img {
    width: auto;
  }
}
@media (min-width: 992px) {
  .list-desk img {
    display: none;
  }
}
@media (min-width: 992px) {
  .list-desk {
    background: url(../img/circle-img.png) no-repeat center center;
    background-size: 480px;
  }
}
@media (min-width: 1200px) {
  .list-desk {
    background-size: 515px;
  }
}
.list-desk .col-4 {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  .list-desk .col-4 {
    width: 33.33333333%;
  }
}
.list-desk .col-5 {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  .list-desk .col-5 {
    width: 41.66666667%;
  }
}
.list-desk .left-circle {
  padding-left: 25px;
  background: url(../img/green-elipsis.png) no-repeat center left;
  text-align: left;
}
.list-desk .right-circle {
  padding-right: 25px;
  background: url(../img/green-elipsis.png) no-repeat center right;
  text-align: right;
}
.list-desk span {
  color: #010101;
  font-family: 'Exo2-Regular';
  display: block;
  font-size: 13px;
}
@media (min-width: 1200px) {
  .list-desk span {
    font-size: 14px;
  }
}
.list-desk .left-part {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .list-desk .left-part {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .list-desk .left-part .first {
    width: 335px;
    margin: 10px 0 45px 15px;
    position: relative;
    left: 30px;
  }
}
@media (min-width: 992px) {
  .list-desk .left-part .second {
    margin-right: 50px;
  }
}
@media (min-width: 992px) {
  .list-desk .left-part .third {
    margin: 90px 75px 70px 0;
  }
}
@media (min-width: 992px) {
  .list-desk .left-part .fourth {
    margin-right: 50px;
  }
}
@media (min-width: 992px) {
  .list-desk .left-part .fifth {
    margin: 40px 0 0 0;
    position: relative;
    left: 30px;
  }
}
.list-desk .right-part {
  margin-top: 20px;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .list-desk .right-part {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .list-desk .right-part .first {
    width: 335px;
    margin: 20px 0 45px 15px;
    position: relative;
    left: 30px;
  }
}
@media (min-width: 992px) {
  .list-desk .right-part .second {
    margin-left: 120px;
  }
}
@media (min-width: 992px) {
  .list-desk .right-part .third {
    margin: 70px 0 70px 160px;
  }
}
@media (min-width: 992px) {
  .list-desk .right-part .fourth {
    margin-left: 125px;
  }
}
@media (min-width: 992px) {
  .list-desk .right-part .fifth {
    margin: 55px 0 0 0;
    position: relative;
    left: 30px;
  }
}
@media (min-width: 1200px) {
  .list-desk .right-part .fifth {
    margin: 30px 0 0 0;
    left: 44px;
  }
}
@media (min-width: 1200px) {
  .profit {
    background: url(../img/profit-bg.png) no-repeat top right, url(../img/elipsis.png) no-repeat right 40px top 40px;
  }
}
.profit h2 {
  margin: 30px 0 25px 0;
}
.profit h6 {
  font-size: 26px;
  color: #000;
  font-family: 'Exo2-Light';
  text-transform: uppercase;
  padding-left: 33px;
  margin-bottom: 25px;
}
.profit h6 br {
  display: none;
}
@media (min-width: 1200px) {
  .profit h6 br {
    display: block;
  }
}
.profit p {
  color: #010101;
  font-family: 'Exo2-Regular';
  font-size: 16px;
  padding-left: 33px;
  text-align: justify;
}
.profit .col-custom-2-5 {
  padding-top: 88px;
  float: left;
  margin-bottom: 25px;
  width: 100%;
  text-align: center!important;
}
@media (min-width: 586px) {
  .profit .col-custom-2-5 {
    width: 50%;
  }
}
@media (min-width: 790px) {
  .profit .col-custom-2-5 {
    width: 20%;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .profit .col-custom-2-5 {
    padding-left: 9px;
    padding-right: 9px;
  }
}
.profit .col-custom-2-5 p {
  font-size: 17px;
  padding-left: 0;
  margin-bottom: 0;
  width: 100%;
  max-width: 190px;
  display: inline-block;
  text-align: center;
}
.profit .star {
  background: url(../img/font-icon/star.png) no-repeat center top;
}
.profit .user {
  background: url(../img/font-icon/users.png) no-repeat center top;
}
.profit .flag {
  background: url(../img/font-icon/flag.png) no-repeat center top;
}
.profit .like {
  background: url(../img/font-icon/like.png) no-repeat center top;
}
.profit .chart {
  background: url(../img/font-icon/chart.png) no-repeat center top;
}
@media (min-width: 380px) {
  .profit .chart {
    width: 100%;
  }
}
@media (min-width: 790px) {
  .profit .chart {
    width: 20%;
  }
}
.about-product {
  padding: 70px 0;
}
@media (min-width: 992px) {
  .about-product {
    padding: 70px 0 140px 0;
  }
}
.about-product h4 {
  font-size: 30px;
  font-family: 'Exo2-Light';
  color: #000;
  text-transform: uppercase;
  margin: 19px 0;
  text-align: center;
}
@media (min-width: 500px) {
  .about-product h4 {
    text-align: left;
  }
}
.about-product p {
  padding: 16px 0 17px 30px;
  background-color: #b0cb1f;
  font-size: 16px;
  color: #fff;
  font-family: 'Exo2-Regular';
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .about-product p {
    left: -30px;
  }
}
.about-product ul {
  margin-left: 45px;
}
@media (min-width: 992px) {
  .about-product ul {
    margin-left: 17px;
  }
}
.about-product li {
  color: #010101;
  font-size: 14px;
  list-style: disc;
  font-family: 'Exo2-Regular';
}
.about-product .row-holder {
  margin-bottom: 40px;
}
.row-holder {
  opacity: 0;
  transition: opacity 1s 1s;
}
.js .animated .row-holder {
  animation: slideInLeft  1.5s 1s;
  opacity: 1;
}
.row-holder-right {
  opacity: 0;
  transition: opacity 1s 1s;
}
.js .animated .row-holder-right {
  animation: slideInRight 1.5s 1s;
  opacity: 1;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .about-product .row-holder-right img {
    margin-top: -720px;
    padding-bottom: 340px;
  }
  .about-product .row-holder-right h4 {
    padding-top: 370px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .about-product .row-holder-right img {
    margin-top: -720px;
    padding-bottom: 400px;
  }
  .about-product .row-holder-right h4 {
    padding-top: 280px;
  }
}
.about-product .row-holder-right {
  margin-bottom: 40px;
}
.about-product .row-holder-right h4 {
  margin: 0 0 10px 0;
}
.about-product .row-holder-right p {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .about-product .row-holder-right p {
    width: 545px;
  }
}
/*.how-order  {
padding:  50px 0 20px 0;
background:  url(../img/order-bg.png) no-repeat center right;
background-size:  cover;
overflow:  hidden;
}
@media (min-width: 768px)  {
.how-order  {
padding:  65px 0 90px 0;
}
}
@media (min-width: 1200px)  {
.how-order  {
background-size:  auto;
}
}
.how-order h2  {
font-family:  'Exo2-Bold';
text-transform:  uppercase;
font-size:  25px;
border-left:  4px solid #b0cb1f;
margin:  0;
padding-left:  30px;
text-align:  left;
margin-bottom:  25px;
}
@media (min-width: 768px)  {
.how-order h2  {
font-size:  45px;
}
}
@media (min-width: 992px)  {
.how-order h2  {
margin-bottom:  75px;
}
}
.how-order .holder  {
width:  100%;
position:  relative;
}
@media (min-width: 992px)  {
.how-order .holder  {
text-align:  left;
}
}
@media (min-width: 1200px)  {
.how-order .holder  {
margin-left:  70px;
width:  calc(100% - 70px);
}
}
.how-order .step  {
line-height:  .6;
position:  relative;
margin-bottom:  30px;
}
@media (min-width: 768px)  {
.how-order .step  {
width:  50%;
display: block;
float:  left;
height:  100px;
margin-bottom:  40px;
text-align:  left;
}
}
@media (min-width: 1200px)  {
.how-order .step  {
width:  300px;
margin:  0 90px 90px 0;
float:  none;
height:  auto;
}
}
.how-order .step span  {
font-size:  130px;
font-family:  'Exo2-Bold';
}
.how-order .step p  {
display:  inline-block;
line-height:  1;
position:  absolute;
left:  50%;
top:  50%;
transform:  translateX(-20%) translateY(-50%);
font-size:  16px;
font-family:  'Exo2-Regular';
margin-bottom:  0;
width:  100%;
white-space:  nowrap;
}
@media (min-width: 390px)  {
.how-order .step p  {
font-size:  18px;
}
}
@media (min-width: 1200px)  {
.how-order .step.nomargin  {
margin:  0;
}
}
@media (min-width: 1200px)  {
.how-order .step.step-2  {
margin-right:  72px;
}
}
.how-order .step.step-2:after  {
content:  '';
position:  absolute;
top:  50%;
right:  -70px;
height:  12px;
width:  37px;
background-color:  #fff;
display:  none;
}
@media (min-width: 1200px)  {
.how-order .step.step-2:after  {
display:  block;
}
}
.how-order .step a  {
display:  inline-block;
padding:  18px 40px;
background-color:  #b0cb1f;
color:  #fff;
font-size:  18px;
font-family:  'Exo2-Bold';
}
.how-order .step a:hover  {
background-color:  #c7e235;
}
.how-order .step a:active  {
background-color:  #90a714;
}
.how-order .step.step-3:after  {
height:  210px;
width:  70px;
background:  url(../img/rounded.png) no-repeat center right;
content:  '';
position:  absolute;
left:  110%;
top:  30px;
display:  none;
}
@media (min-width: 1200px)  {
.how-order .step.step-3:after  {
display:  block;
}
}
.how-order .step-button  {
position:  relative;
text-align:  center;
}
@media (min-width: 768px)  {
.how-order .step-button  {
top:  25px;
}
}
@media (min-width: 1200px)  {
.how-order .step-button  {
text-align:  left;
}
}
.how-order .black p  {
color:  #000;
}
.how-order .black span  {
color:  #000;
}
.how-order .black:after  {
content:  '';
position:  absolute;
top:  50%;
margin-top:  -2.5px;
right:  -76px;
width:  100px;
height:  5px;
background-color:  #e5e5e5;
display:  none;
}
@media (min-width: 1200px)  {
.how-order .black:after  {
display:  block;
}
}
.how-order .white p  {
color:  #fff;
}
.how-order .white span  {
color:  #fff;
}
@media (min-width: 1200px)  {
.how-order .step-4, .how-order .step-5  {
float:  right;
}
}*/
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .how-order {
    overflow: hidden;
    position: relative;
    padding-top: 65px;
  }
  .how-order:before {
    height: 100% !important;
    right: 0!important;
    margin-right: 0!important;
  }
  .how-order .container {
    padding-top: 105px;
  }
  .how-order .black p {
    color: #fff!important;
  }
  .how-order .black span {
    color: #fff!important;
  }
}
.how-order {
  overflow: hidden;
  position: relative;
  /*margin-right: -500px;
    padding-right: 500px;*/
  /*  padding: 50px 0 20px 0;
    background: url(../img/order-bg.png)no-repeat center right;
    //    background-size: cover;
    overflow: hidden;*/
  /*@media only screen and (min-width: 1920px) {
    background-size: auto;
    background-position: 50% center;
}
    @media only screen and (min-width: 1024px) and (max-width:1440px) {
    background-size: auto;
    background-position: 458px center;
}*/
}
.how-order:before {
  position: absolute;
  content: "";
  background: url(../img/order-bg.png) no-repeat;
  background-size: cover;
  width: 1920px;
  height: 600px;
  right: 50%;
  margin-right: -1746px;
}
@media (min-width: 768px) {
  .how-order {
    padding: 65px 0 90px 0;
  }
}
.how-order h2 {
  font-family: 'Exo2-Bold';
  text-transform: uppercase;
  font-size: 25px;
  border-left: 4px solid #b0cb1f;
  margin: 0;
  padding-left: 30px;
  text-align: left;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .how-order h2 {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .how-order h2 {
    margin-bottom: 75px;
  }
}
.how-order .holder {
  width: 100%;
  position: relative;
}
@media (min-width: 992px) {
  .how-order .holder {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .how-order .holder {
    padding-top: 65px;
    margin-left: 20px;
    width: calc(100% - 70px);
  }
}
.how-order .step {
  line-height: .6;
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .how-order .step {
    width: 50%;
    margin-left: 200px;
    display: inline-block;
    height: 100px;
    margin-bottom: 40px;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .how-order .step {
    width: 300px;
    margin: 0 90px 90px 0;
    float: none;
    height: auto;
  }
}
.how-order .step span {
  font-size: 130px;
  font-family: 'Exo2-Bold';
}
.how-order .step p {
  display: inline-block;
  line-height: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-20%) translateY(-50%);
  font-size: 16px;
  font-family: 'Exo2-Regular';
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
}
@media (min-width: 390px) {
  .how-order .step p {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .how-order .step.nomargin {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .how-order .step.step-2 {
    margin-right: 72px;
  }
}
.how-order .step.step-2:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -70px;
  height: 12px;
  width: 37px;
  background-color: #fff;
  display: none;
}
@media (min-width: 1200px) {
  .how-order .step.step-2:after {
    display: block;
  }
}
.how-order .step a {
  display: inline-block;
  padding: 18px 40px;
  background-color: #b0cb1f;
  color: #fff;
  font-size: 18px;
  font-family: 'Exo2-Bold';
}
.how-order .step a:hover {
  background-color: #c7e235;
}
.how-order .step a:focus {
  background-color: #90a714;
}
.how-order .step.step-3:after {
  height: 210px;
  width: 70px;
  background: url(../img/rounded.png) no-repeat center right;
  content: '';
  position: absolute;
  left: 110%;
  top: 30px;
  display: none;
}
@media (min-width: 1200px) {
  .how-order .step.step-3:after {
    display: block;
  }
}
.how-order .step-button {
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .how-order .step-button {
    top: 25px;
  }
}
@media (min-width: 1200px) {
  .how-order .step-button {
    text-align: left;
  }
}
.how-order .black p {
  color: #000;
}
.how-order .black span {
  color: #000;
}
.how-order .black:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -2.5px;
  right: -76px;
  width: 100px;
  height: 5px;
  background-color: #e5e5e5;
  display: none;
}
@media (min-width: 1200px) {
  .how-order .black:after {
    display: block;
  }
}
.how-order .white p {
  color: #fff;
}
.how-order .white span {
  color: #fff;
}
@media (min-width: 1200px) {
  .how-order .step-4,
  .how-order .step-5 {
    float: right;
  }
}
.in-number {
  padding: 50px 0 65px 0;
}
.in-number h2 {
  font-family: 'Exo2-Bold';
  text-transform: uppercase;
  font-size: 25px;
  border-left: 4px solid #b0cb1f;
  margin: 0;
  padding-left: 30px;
  margin-bottom: 40px;
  text-align: left;
}
@media (min-width: 768px) {
  .in-number h2 {
    font-size: 45px;
  }
}
.in-number .row-holder {
  position: relative;
}
.in-number .img-holder {
  display: none;
}
@media (min-width: 992px) {
  .in-number .img-holder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: url(../img/team.jpg) no-repeat center;
    background-size: cover;
  }
}
@media (min-width: 992px) {
  .in-number .content-holder {
    float: right;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .content-holder {
    margin-left: 75px;
  }
}
.in-number .content-holder span {
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  line-height: 27px;
  padding: 0 10px;
  display: inline-block;
  font-size: 16px;
  font-family: 'Exo2-Bold';
}
.in-number .number-value {
  font-size: 65px;
  font-family: 'Exo2-Bold';
  color: #b0cb1f;
  margin: 0 0 5px 0;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
}
.in-number .number-value span.number-value {
  position: absolute;
  bottom: 0;
  background: none;
  font-size: 36px;
  font-family: 'Exo2-Bold';
  color: #b0cb1f;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
}
.in-number p {
  font-family: 'Exo2-Regular';
  font-size: 16px;
  color: #000;
  margin: 0;
  line-height: 1;
}
.in-number .box {
  margin-bottom: 40px;
}
.in-number .last-box {
  margin-bottom: 0;
}
.in-number .box-holder {
  text-align: center;
}
@media (min-width: 500px) {
  .in-number .box-holder {
    width: 50%;
    display: block;
    float: left;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .in-number .box-holder {
    max-width: 260px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .our-clients {
    display: none;
  }
}
.our-clients {
  padding: 0 0 100px 0;
  text-align: center;
}
.our-clients h5 {
  margin-bottom: 70px !important;
  text-transform: uppercase;
  font-size: 26px;
  font-family: 'Exo2-Light';
  margin: 0;
}
.slick-arrow {
  height: 38px;
  width: 38px;
  border: none;
  color: transparent;
  outline: none;
}
.slick-next {
  position: absolute;
  bottom: -38px;
  right: 0;
  background: url(../img/next-arrow-clients.png) no-repeat center center;
  background-color: #b0cb1f;
  transition: 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .slick-next {
    top: 50%;
    right: -38px;
    margin-top: -19px;
  }
}
.slick-next:hover {
  background-color: #c7e235;
}
.slick-next:active {
  background-color: #90a714;
}
.slick-prev {
  position: absolute;
  bottom: -38px;
  right: 38px;
  background: url(../img/prev-arrow-clients.png) no-repeat center center;
  background-color: #b0cb1f;
  transition: 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .slick-prev {
    top: 50%;
    left: -38px;
    margin-top: -19px;
  }
}
.slick-prev:hover {
  background-color: #c7e235;
}
.slick-prev:active {
  background-color: #90a714;
}
.client-holder {
  position: relative;
  width: auto;
  height: 100px;
  max-height: 190px;
}
.client-holder img {
  /*position:  absolute;
    top:  50%;
    left:  50%;
    transform:  translateX(-50%) translateY(-50%);
    width:  90%;
    */
}
@media (min-width: 768px) {
  .client-holder img {
    width: auto;
  }
}
.contact h2 {
  font-family: 'Exo2-Bold';
  text-transform: uppercase;
  font-size: 25px;
  border-left: 4px solid #b0cb1f;
  margin: 0;
  padding-left: 30px;
  text-align: left;
}
@media (min-width: 768px) {
  .contact h2 {
    font-size: 45px;
  }
}
@media (min-width: 992px) {
  .contact h2 {
    margin-bottom: 40px;
  }
}
.embed-responsive-4by3 {
  padding-bottom: 30%;
  position: relative;
}
@media (min-width: 992px) {
  .embed-responsive-4by3 {
    padding-bottom: 40%;
  }
}
@media (min-width: 1200px) {
  .embed-responsive-4by3 {
    padding-bottom: 33%;
  }
}
.no-scroll {
  z-index: 1;
}
.no-scroll:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  content: '';
}
.text-center {
  text-align: left!important;
}
.address {
  padding: 25px 20px 20px 20px;
  background-color: #fff;
  width: 300px;
  z-index: 10;
  box-sizing: border-box;
  text-align: left;
  display: inline-block;
}
@media (min-width: 992px) {
  .address {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.address address,
.address p {
  color: #010101;
  font-size: 16px;
  font-family: "Exo2-Regular";
  margin-bottom: 20px;
}
.address p {
  margin-bottom: 0;
}
.address a {
  font-family: "Exo2-Regular";
  font-size: 16px;
  color: #010101;
}
.address a:hover {
  color: #c7e235 !important;
}
.address .tel {
  font-size: 24px;
}
.address .hr {
  border-top: 1px solid #dadada;
  margin: 20px 0;
  margin-right: -20px;
}
.address h6 {
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Exo2-Bold";
  margin: 0 0 5px 0;
}
.address button {
  display: block;
  width: 100%;
  height: 48px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  background: #b0cb1f;
  margin-top: 10px;
  border: none;
  font-family: "Exo2-Bold";
}
.address button:hover {
  background-color: #c7e235;
}
.address button:active {
  background-color: #90a714;
}
.footer {
  padding: 10px 0;
  text-align: center;
}
@media (min-width: 500px) {
  .footer {
    padding: 25px 0;
  }
}
.footer span {
  color: #787878;
  font-size: 14px;
  font-family: "Exo2-Regular";
  margin-top: 10px;
  display: block;
}
@media (min-width: 500px) {
  .footer span {
    float: left;
  }
}
.footer a {
  color: #787878;
  font-size: 14px;
  font-family: "Exo2-Regular";
  padding: 5px 15px 5px 5px;
  position: relative;
  transition: 0.3s ease-in-out;
  margin-top: 5px;
}
.footer a:hover {
  color: #c7e235 !important;
}
.footer a:active {
  color: #90a714 !important;
}
@media (min-width: 500px) {
  .footer a {
    float: right;
  }
}
.footer a:after {
  position: absolute;
  border-bottom: 15px solid #dcdcdc;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: '';
  bottom: 7px;
  right: -5px;
  transition: 0.3s ease-in-out;
}
.footer a:hover {
  color: #545454;
}
.footer a:hover:after {
  border-bottom-color: #c7e235;
  border-left-color: transparent;
  border-right-color: transparent;
}
.footer a:active:after {
  border-bottom-color: #90a714;
  border-left-color: transparent;
  border-right-color: transparent;
}
.footer .button {
  height: 35px;
  text-transform: uppercase;
  background: #b0cb1f;
  font-size: 14px;
  display: inline-block;
  padding: 8px 18px;
  border: none;
  color: #fff;
}
.footer .button:after {
  display: none;
}
.footer .button:hover {
  background-color: #c7e235;
  color: #fff !important;
}
.footer .button:active {
  background-color: #90a714;
  color: #fff !important;
}
.footer .holder-a {
  display: block;
  margin: 10px 0;
}
@media (min-width: 500px) {
  .footer .holder-a {
    display: inline-block;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 640px;
  }
}
@media (min-width: 1200px) {
  .modal-dialog {
    margin-top: 15%;
  }
}
.modal-body {
  padding: 15px;
}
@media (min-width: 992px) {
  .modal-body {
    padding: 30px;
  }
}
.modal-body .jq-file__name {
  color: #b0cb1f;
}
.modal-content {
  border-radius: 0;
  position: relative;
}
.modal-content .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 33px;
  z-index: 12;
  padding: 0 7px;
  border: 2px solid #000;
  color: #000;
  opacity: 1;
  transition: 0.4s ease-in-out;
}
@media (min-width: 992px) {
  .modal-content .close {
    top: 10px;
    right: 15px;
  }
}
.modal-content .close span {
  color: #000;
  opacity: 1;
  transition: 0.4s ease-in-out;
}
.modal-content .close:hover {
  border-color: #c7e235;
}
.modal-content .close:hover span {
  color: #c7e235;
}
.modal-content .close:active {
  outline: none;
  text-decoration: none;
}
.modal-content h5 {
  font-size: 30px;
  color: #000;
  font-family: "Exo2-Bold";
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 30px;
  padding-right: 50px;
}
.modal-content input {
  height: 42px;
  width: 100%;
  display: block;
  padding-left: 18px;
  background-color: #eeeeee;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-family: "Exo2-Regular";
  font-size: 16px;
  color: #6e6e6e;
  border: none;
}
.modal-content input::-webkit-input-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content input::-moz-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content input:-moz-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content input:-ms-input-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content .file-holder {
  position: relative;
  height: 42px;
  width: 100%;
  margin: 6px 0;
}
@media (min-width: 992px) {
  .modal-content .file-holder {
    margin-bottom: 0;
  }
}
.modal-content .file-holder input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-content textarea {
  height: 146px;
  resize: none;
  width: 100%;
  display: block;
  font-family: "Exo2-Regular";
  font-size: 16px;
  color: #6e6e6e;
  border: none;
  padding: 15px 10px 10px 15px;
  box-sizing: border-box;
  background-color: #eeeeee;
}
.modal-content textarea::-webkit-input-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content textarea::-moz-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content textarea:-moz-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content textarea:-ms-input-placeholder {
  color: #6e6e6e;
  font-family: "Exo2-Regular";
}
.modal-content .submit {
  display: block;
  width: 100%;
  height: 44px;
  text-align: center;
  color: #fff;
  background-color: #b0cb1f;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Exo2-Bold';
  margin-top: 15px;
  outline: none;
  border: 1px solid #b0cb1f;
}
.modal-content .submit:hover {
  background-color: #c7e235;
  border-color: #c7e235;
}
.modal-content .submit:active {
  background-color: #90a714;
  border-color: #90a714;
}
@media (min-width: 450px) {
  #callbackModal .modal-dialog {
    width: 410px;
    margin-left: auto;
    margin-right: auto;
  }
}
#callbackModal .modal-content h5 {
  font-size: 22px;
}
@media (min-width: 450px) {
  #callbackModal .modal-content h5 {
    font-size: 30px;
  }
}
@media (min-width: 450px) {
  #callbackModal2 .modal-dialog {
    width: 410px;
    margin-left: auto;
    margin-right: auto;
  }
}
#callbackModal2 .modal-content h5 {
  font-size: 22px;
}
@media (min-width: 450px) {
  #callbackModal2 .modal-content h5 {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .mobile-product {
    padding-top: 70px;
    background: none;
  }
}
@media (min-width: 768px) {
  .mobile-product .col-title {
    margin-top: -8px;
  }
}
@media (min-width: 992px) {
  .mobile-product .col-title {
    margin-top: -32px;
  }
}
@media (min-width: 1200px) {
  .mobile-product .col-title {
    margin-top: -28px;
  }
}
@media (min-width: 768px) {
  .stationar-product h2 {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  .stationar-product h2 {
    font-size: 43px;
  }
}
@media (min-width: 1200px) {
  .stationar-product h2 {
    font-size: 45px;
  }
}
@media (min-width: 768px) {
  .glass-product {
    padding-top: 90px;
    background: none;
  }
}
@media (min-width: 768px) {
  .glass-product .col-title {
    margin-top: -8px;
  }
}
@media (min-width: 992px) {
  .glass-product .col-title {
    margin-top: -32px;
  }
}
@media (min-width: 1200px) {
  .glass-product .col-title {
    margin-top: -69px;
  }
}
.location__map {
  height: 250px;
}
@media (min-width: 992px) {
  .location__map {
    height: 500px;
  }
}
.jq-file {
  width: 100%;
}
.jq-file input {
  height: auto;
  line-height: 1em;
  cursor: pointer;
}
.jq-file__name {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  line-height: 39px;
  padding: 0 30px 0 63px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 63px;
  border: 1px solid #b0cb1f;
  font-size: 17px;
  color: #fff;
  font-family: 'Exo2-Regular';
  letter-spacing: 1px;
  text-align: left;
}
.jq-file__browse {
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  width: 42px;
  background: #b0cb1f;
  color: transparent;
}
.jq-file__browse:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: '+';
  color: #fff;
  font-size: 30px;
}
.jq-file:hover .jq-file__browse {
  background: #c7e235;
}
.jq-file:active .jq-file__browse {
  background-color: #90a714;
}
.jq-file.focused .jq-file__name {
  border-color: #90a714;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}
form label.error {
  color: #f00;
  font-size: 15px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-family: 'Exo2-Regular';
}
.fourth-form .button input {
  height: 44px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #b0cb1f !important;
  display: inline-block;
  padding: 0 18px !important;
  border: none;
  margin-top: 5px;
  margin-bottom: -25px;
  font-size: 18px;
  font-family: "Exo2-Bold";
}
.fourth-form .button input:hover {
  background-color: #c7e235 !important;
}
.fourth-form .button input:active {
  background-color: #90a714 !important;
}
.button input {
  height: 44px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #b0cb1f !important;
  font-size: 18px;
  display: inline-block;
  padding: 0 18px !important;
  border: none;
  margin-top: 5px;
  margin-bottom: -25px;
  font-family: "Exo2-Bold";
}
.button input:hover {
  background-color: #c7e235 !important;
}
.button input:active {
  background-color: #90a714 !important;
}
div.wpcf7-validation-errors,
span.wpcf7-not-valid-tip,
.wpcf7-response-output {
  display: none !important;
}
div.wpcf7-response-output {
  margin: 7px 0 0 !important;
  font-size: 14px;
}
.wpcf7-not-valid {
  border-radius: 3px;
  border: 2px solid #f00 !important;
}
#popup__tnx {
  display: none;
  width: 550px;
  margin: 0 auto;
  position: relative;
}
#popup__tnx .modal-content {
  padding: 0 70px 0 0;
}
#popup__tnx .close {
  display: none;
}
#popup__tnx .modal-content {
  border: none;
  box-shadow: none;
}
.fancybox-item,
.fancybox-close {
  top: 32px;
  right: 30px;
  background: url(../img/close_btn.png);
  width: 32px;
  height: 32px;
}
.fancybox-item:hover,
.fancybox-close:hover {
  background-position: 0 -32px;
}
.fancybox-item:active,
.fancybox-close:active {
  background-position: 0 -64px;
}
@keyframes hvr-wobble-skew {
  16.65% {
    transform: skew(-12deg);
  }
  33.3% {
    transform: skew(10deg);
  }
  49.95% {
    transform: skew(-6deg);
  }
  66.6% {
    transform: skew(4deg);
  }
  83.25% {
    transform: skew(-2deg);
  }
  100% {
    transform: skew(0);
  }
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-150%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft2 {
  from {
    transform: translate3d(-200%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    transform: translate3d(150%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse2 {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes slideInDown {
  from {
    transform: translate3d(0, -150%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 150%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
